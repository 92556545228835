import './App.css';
import Sidenav from './components/SideNav';
import Login from './components/Login';
import Header from './components/Header';
import Routes from './Routes/Routes.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import bctl from './images/bc-tl.svg'
import bcbr from './images/bc-br.svg'

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginContainer}/>
        <Route component={DefaultContainer}/>
      </Switch>
    </Router>

  );
}

const LoginContainer = () => (
  <div className="login-container">
    {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
    <div className="backdrop">
        <div className="back-container">
            <img alt="" src={bctl} className="bc-tl" />
            <img alt="" src={bcbr} className="bc-br" />
        </div>
      </div>
    <Route exact path="/" component={Login} />
  </div>
)

const DefaultContainer = () => (
  <div>
    <div className="App">
      <div className="backdrop">
        <div className="back-container">
            <img alt="" src={bctl} className="bc-tl" />
            <img alt="" src={bcbr} className="bc-br" />
        </div>
      </div>
      <div className="row row-main">
        <Sidenav />
        <div className="col-lg-10 col-md-8 col-sm-8" >
          <Header />
          <Route component={Routes} />
        </div>
      </div>
    </div>
  </div>
)


export default App;
