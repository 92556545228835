import React from 'react';
import './Login.css';
import url from '../config/axios'
import brandImage from '../images/brandImage.png'
import { useState } from 'react';
import { useHistory } from "react-router-dom";

function Login() {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const loginUser = () => {
        url.post(`/admin/login/`, { email: email, password: password }).then((res) => {
            console.log(res);
            if (res.data.Message === "Success") {
                alert("User Logged In Successfully");
                localStorage.setItem("AdminDetails", JSON.stringify(res.data.data));
                history.push('/dashboard');
            }
            else {
                alert(res.data.data);
            }
        }).catch(e => alert(e));
    }
    return (
        <div className="col-md-12 login">
            <div>
                <div className="logoAuth">
                    <img alt="yo1" src={brandImage}
                        className="img-fluid " width="100" />
                </div>
                <div className="">
                    <div className="group-comb ">
                        <div className="form-group group-comb-2">
                            <label for="exampleFormControlInput1">Email</label>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="admin@xyz.com" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="group-comb ">
                        <div className="form-group group-comb-2">
                            <label for="exampleFormControlInput1">Password</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="password" />
                        </div>
                    </div>
                </div>
                <div className=" rememberMe">
                    <input type="checkbox" id="" name="" value="" /> Remember Me
                </div>
                <div className="forgot-pass">
                    <a href="!#"> Forgot Password ?</a>
                </div>
                <div className="">
                    <button className="btn btn-success login-btn" onClick={() => loginUser()}>Login</button>
                </div>
            </div>
        </div>

    )
}



export default Login;