import React from 'react';
import './SideNav.css';
import { NavLink } from 'react-router-dom'
import brandImage from '../images/brandImage.png'
import { useHistory } from "react-router-dom";

function Sidenav() {
    let history = useHistory();
    const Routes = [
        {
            name: 'Dashboard',
            route: '/dashboard',
            icon: 'fas fa-tachometer-alt'
        },
        {
            name: 'Outlet Chains',
            route: '/outletchains',
            icon: 'fas fa-store-alt'
        },
        {
            name: 'Catalog',
            route: '/catalog',
            icon: 'fas fa-tag'
        },
        // {
        //     name: 'Unapproved Products',
        //     route: '/unapproved-products',
        //     icon: 'fas fa-exclamation-circle'
        // },
        {
            name: 'Categories',
            route: '/categories',
            icon: 'fas fa-shapes'
        },
        {
            name: 'Manufacturers',
            route: '/brands',
            icon: 'fas fa-tags'
        }
    ];

    const logout = () => {
        localStorage.clear();
        history.push('/')
    }
    return (
        <div className="col-lg-2 col-md-4 col-sm-4 side-nav">
            <div className="card card-side-nav">
                <div>
                    <div>
                        <div className="logo cannaby-logo">
                            <img alt="yo4" src={brandImage}
                                className="img-fluid " width="100" />
                        </div>

                        {Routes.map((router, index) => {
                            return (
                                <ul className="nav nav-pills flex-column cannaby-nav" key={index}>
                                    <li className="nav-item">
                                        <NavLink activeClassName="active" className="nav-link " to={router.route}><i className={router.icon}></i>{router.name}</NavLink>
                                    </li>
                                </ul>
                            )
                        })}
                        {/* <ul className="nav nav-pills flex-column cannaby-nav">
                <li className="nav-item">
                    <a  href="/" className="nav-link " to="/dashboard"><i className="fas fa-tachometer-alt"></i><span>Dashboard</span> </a>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link " to="/outletchains"><i className="fas fa-store-alt"></i> Outlet Chains</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link " to="/catalog"><i className="fas fa-tag"></i> Catalog</NavLink>
                </li>
                <li className="nav-item">
                    <a href='/' className="nav-link " to="/unaprovedproducts"><i className="fas fa-exclamation-circle"></i><span>Unapproved Products</span></a>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link " to="/categories"><i className="fas fa-shapes"></i>Categories</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName="active" className="nav-link " to="/brands"><i className="fas fa-tags"></i>Brands</NavLink>
                </li>
                
                </ul> */}
                    </div>

                    <div>
                        <button onClick={() => logout()} className="btn btn-danger"><i className="fas fa-sign-out-alt"></i>logout</button>
                    </div>
                </div>


            </div>


        </div>

    )
}



export default Sidenav;