/* eslint-disable react-hooks/exhaustive-deps */
import "./Catalog.css";
import { useEffect, useState, useCallback } from "react";
import url from "../../config/axios";
// const product = JSON.parse(localStorage.getItem('product'));
import { useHistory } from "react-router-dom";
// MUI Multi
import storage from "../../config/firebase";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import XLSX from "xlsx";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import VariantPage from "./variants";
// MUI Multi
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const effectsList = ["Calm", "Happy", "Energetic", "relaxed"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddCatalog = () => {
  let history = useHistory();
  const [name, setName] = useState("");
  // eslint-disable-next-line
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("null");
  const [cbd, setCbd] = useState("");
  const [strainType, setStrainType] = useState("");
  const [thc, setThc] = useState("");
  const [sDesc, setSDesc] = useState("");
  const [ldesc, setLDesc] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [width, setWidth] = useState("");
  const [effect, setEffects] = useState([]);
  const [countSDesc, setCountSDesc] = useState("0");
  const [countPName, setCountPName] = useState("0");
  const [status, setStatus] = useState(false);

  const [selCategories, setSelCategories] = useState([]);
  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [effectName, setEffectName] = useState([]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);

  const [cropOne, setCropOne] = useState({ x: 0, y: 0 });
  const [zoomOne, setZoomOne] = useState(1);
  const [croppedAreaPixelsOne, setCroppedAreaPixelsOne] = useState(null);
  const [showCrop1, setShowCrop1] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [cropTwo, setCropTwo] = useState({ x: 0, y: 0 });
  const [zoomTwo, setZoomTwo] = useState(1);
  const [croppedAreaPixelsTwo, setCroppedAreaPixelsTwo] = useState(null);
  const [showCrop2, setShowCrop2] = useState(false);
  const [quantiy, setQuantity] = useState("");
  const [open2, setOpen2] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [convertedJson, setConvertedJson] = useState([]);
  const [price, setPrice] = useState("");
  const [variants, setVariants] = useState([]);
  const [variantFlow, setVariantFlow] = useState("no-variants");

  // Validation UseState
  const [validation, setValidation] = useState({});
  const validateForm = () => {
    let data = { ...validation };
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (name.trim().length === 0) {
      data = { ...data, ProdNameErr: "Product Name is required" };
    } else {
      let { ProdNameErr, ...others } = data;
      data = { ...others };
    }
    if (image.trim().length === 0) {
      data = { ...data, imageErr: "Image is required" };
    } else {
      let { imageErr, ...others } = data;
      data = { ...others };
    }
    setValidation({ ...data });
    return data;
  };

  useEffect(() => {
    GetCategories();
    GetBrands();
  }, []);

  const ExcelFileUpload = (event) => {
    // console.log(event)
    let selected = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(selected);
    fileReader.onload = (event) => {
      console.log(event);
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: "binary" });
      console.log(workbook);
      workbook.SheetNames.forEach((sheet) => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        console.log(data);
        setConvertedJson(data);
      });
    };
  };

  const uploadMultipleData = async () => {
    console.log(convertedJson);
    const body = {
      json: convertedJson,
    };
    await url
      .post("/product/multiple", body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          alert("File Uploaded Successfully");
        } else {
          alert("Products were duplicate: File Uploaded Successfully");
        }
      })
      .catch((e) => console.log(e));
    setConvertedJson([]);
  };

  const onCropCompleteTwo = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsTwo(croppedAreaPixels);
  }, []);

  const showCroppedImageTwo = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          image2,
          croppedAreaPixelsTwo,
          0
        );
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage2(croppedImage);
        setShowCrop2(false);
        setOpen2(false);
        fileUploadTwo();
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixelsTwo, image2]
  );

  const onCropCompleteOne = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsOne(croppedAreaPixels);
  }, []);

  const showCroppedImageOne = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          image1,
          croppedAreaPixelsOne,
          0
        );
        console.log("donee", croppedImage);
        localStorage.setItem("ImageSet", croppedImage);
        setImage1(croppedImage);
        setShowCrop1(false);
        setOpen1(false);
        fileUploadOne();
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixelsOne, image1]
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", croppedImage);
        // setBaseOne(croppedImage);
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
        fileUploadNew();
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      console.log(event.target.files[0], "file image");
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const imageSelector1 = (event) => {
    if (event.target.files[0]) {
      setImage1(URL.createObjectURL(event.target.files[0]));
      setFile1(event.target.files[0]);
      setShowCrop1(true);
      setOpen1(true);
    }
  };

  const imageSelector2 = (event) => {
    if (event.target.files[0]) {
      setImage2(URL.createObjectURL(event.target.files[0]));
      setFile2(event.target.files[0]);
      setShowCrop2(true);
      setOpen2(true);
    }
  };

  const fileUploadNew = async () => {
    // console.log(image, 'image');
    // console.log(localStorage.getItem("ImageSet"), 'base64');
    // console.log(uploadTask, 'image file:');
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      await uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              setImage(blob);
            });
        }
      );
    } else {
      setImage(image);
    }
  };

  const fileUploadOne = async () => {
    if (file1 !== "") {
      const uploadTask1 = storage
        .ref(`images/${file1.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      await uploadTask1.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file1.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              setImage1(blob);
            });
        }
      );
    } else {
      setImage1(image1);
    }
  };

  const fileUploadTwo = async () => {
    if (file2 !== "") {
      const uploadTask2 = storage
        .ref(`images/${file2.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      await uploadTask2.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file2.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              setImage2(blob);
            });
        }
      );
    } else {
      setImage2(image2);
    }
  };

  const addProduct = () => {
    // console.log(images, "images all:");
    const Error = validateForm();
    console.log(Error);
    if (Object.keys(Error).length === 0) {
      let brandSplit = brand.split(",");
      const body = {
        name: name,
        brandID: brandSplit[1],
        brandName: brandSplit[0],
        category: selCategories,
        image: image,
        imageGallery: [image1, image2],
        cbd: cbd,
        strainType: strainType,
        thc: thc,
        effects: effect,
        shortDescription: sDesc,
        longDescription: ldesc,
        height: height,
        width: width,
        weight: weight,
        status: status,
        price: variantFlow === "variants" ? 0 : price,
        variants: variantFlow === "variants" ? variants : [],
        quantity: quantiy,
      };
      console.log(body);
      url
        .post(`product/`, body)
        .then((res) => {
          console.log(res);
          if (res.data.Message === "Success") {
            setName("");
            setCbd("");
            setThc("");
            setStrainType("");
            setSDesc("");
            setLDesc("");
            setHeight("");
            setWeight("");
            setWidth("");
            setCategory("");
            setBrand("");
            setStatus(false);
            setEffects([]);
            history.push("/catalog");
          } else {
            alert("Error: Product Name already exits");
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const GetCategories = () => {
    url
      .get(`/category/active`)
      .then(async (res) => {
        // console.log(res);
        await setCategories(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const GetBrands = () => {
    url
      .get(`/brand/`)
      .then(async (res) => {
        // console.log(res);
        await setBrands(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const selectedCategories = async (stor) => {
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? stor.split("split") : stor
    );
    // console.log(stor, 'selected One:');
    let obj = [];
    for (let i = 0; i < stor.length; i++) {
      var splitted = stor[i].split("split", 3);
      obj.push({
        // _id: splitted[0],
        _id: splitted[0],
        // name: splitted[1],
        // parentCategory: splitted[2],
      });
      // obj["categoryID"] = await splitted[0];
      // obj["name"] = await splitted[1];
      // obj.push(splitted[0]);
      await console.log(obj, "new array");
      await setSelCategories(obj);

      console.log("check category: ", obj);
    }
    // console.log(effect, 'effects');
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEffectName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setEffects(value);
  };

  const goBack = () => {
    history.push("/catalog");
  };

  const funcshortDescription = (e) => {
    setSDesc(e.target.value);
    setCountSDesc(e.target.value.length);
  };

  const funcPName = (e) => {
    setName(e.target.value);
    setCountPName(e.target.value.length);
  };

  const handleVariantChange = (e) => {
    setVariantFlow(e.target.value);
  };

  const handleVariants = (e) => {
    if (e == "variants") {
      setPrice(0);
    }
    setVariants(e);
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="row">
            <div className="col-md-12 cat-btn">
              <button className="btn btn-outline" onClick={goBack}>
                <i class="fas fa-times"></i> Cancel
              </button>
            </div>
            <div className="col-md-6 add-catalog">
              <div className="row">
                <div className="col-md-12 solo-chain-2">
                  <span class=""> Basic Information</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Product Name *</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => funcPName(e)}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name"
                      />
                    </div>
                    <p className="counter-text">{countPName}/120</p>
                  </div>
                  {validation.ProdNameErr && (
                    <p className="validateERR">{validation.ProdNameErr}</p>
                  )}
                </div>

                <div className="col-md-12">
                  <FormControl
                    style={{
                      marginTop: "-1em",
                    }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="no-variant"
                      name="radio-buttons-group"
                      onChange={(e) => handleVariantChange(e)}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <FormControlLabel
                          value="variants"
                          control={<Radio />}
                          label="Variants"
                        />
                        <FormControlLabel
                          value="no-variant"
                          control={<Radio />}
                          label="No Variant"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                {variantFlow === "variants" ? (
                  <VariantPage handleVariants={handleVariants} />
                ) : (
                  <div
                    style={{
                      marginBottom: "1em",
                      width: "100%",
                    }}
                  >
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Product Price*</label>
                        <input
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          type="text"
                          name="price"
                          placeholder="price"
                          className="form-control"
                          id="exampleFormControlInput1"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Product Quantity*</label>
                      <input
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                        type="text"
                        name="Quantity"
                        placeholder={
                          variantFlow === "variants" ? "grams" : "items"
                        }
                        className="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
                {/* MUI Cmponent */}
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Categories</label>
                      <FormControl sx={{ m: 1, width: 525 }}>
                        <InputLabel id="demo-multiple-chip-label">
                          Categories
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={personName}
                          onChange={(e) => selectedCategories(e.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Categories"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={`${value.split("split")[1]}`}
                                />
                              ))}
                            </Box>
                          )}
                          IconComponent={() => <KeyboardArrowDownIcon />}
                          MenuProps={MenuProps}
                        >
                          {categories.map((category, i) => (
                            <MenuItem
                              key={i}
                              value={
                                category._id +
                                "split" +
                                category.name +
                                "split" +
                                category.parentCategory
                              }
                              style={getStyles(name, personName, theme)}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label for="exampleFormControlSelect1">Category</label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="exampleFormControlSelect1"
                        onChange={(e) => selectedCategories(e.target.value)}
                      >
                        <option>Select Category</option>
                        {categories.map((category, i) => (
                          <option
                            key={i}
                            value={
                              category.categoryID + "split" + category.name
                            }
                          >
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p>Select Category from dropdown</p>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlSelect1">Brand</label>
                      <select
                        className="form-control"
                        onChange={(e) => setBrand(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="">Select Brand</option>
                        {brands.map((b, i) => {
                          return (
                            <option key={i} value={`${b.name},${b._id}`}>
                              {b.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <p>Select Brand from dropdown</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>CBD %</label>
                      <input
                        type="number"
                        value={cbd}
                        onChange={(e) => setCbd(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>THC %*</label>
                      <input
                        type="number"
                        value={thc}
                        onChange={(e) => setThc(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* product details */}

              <div className="row">
                <div className="col-md-12 solo-chain-2">
                  <span>Product Details</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Strain Type</label>
                      <input
                        type="text"
                        value={strainType}
                        onChange={(e) => setStrainType(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Strain Type"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Short Description</label>
                      <textarea
                        type="text"
                        value={sDesc}
                        onChange={(e) => funcshortDescription(e)}
                        className="form-control t-a"
                        id="exampleFormControlInput1"
                        placeholder="name"
                      />
                    </div>
                    <p className="counter-text">{countSDesc}/460</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Long Description</label>
                      {/* <textarea
                        type="text"
                        value={ldesc}
                        name="content" 
                        id="editor"
                        onChange={(e) => setLDesc(e.target.value)}
                        className="form-control t-a"
                        style={{ height: '150px' }}
                        placeholder="name"
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data=""
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setLDesc(data);
                          // console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* packaging details */}

              <div className="row">
                <div className="col-md-12 solo-chain-2">
                  <span class=""> Packaging Details</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Height</label>
                      <input
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                        type="number"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="100"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Width</label>
                      <input
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                        type="number"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="100"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Weight</label>
                      <input
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        type="number"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="100"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 solo-chain-2">
                  <span class="">Status</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status}
                      onChange={() => setStatus(!status)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {/* effects details */}

              <div className="row">
                <div className="col-md-12 solo-chain-2">
                  <span class=" ">Effects</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Effects</label>
                      <FormControl sx={{ m: 1, width: 525 }}>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={effectName}
                          onChange={handleChange}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Effects"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          IconComponent={() => <KeyboardArrowDownIcon />}
                          MenuProps={MenuProps}
                        >
                          {effectsList.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              {/* publicsh details */}
              <div className="row">
                <button
                  type="button"
                  onClick={addProduct}
                  class="btn btn-primary m-2"
                >
                  Publish
                </button>
                {/* <button className="btn btn-danger">
                  <i class="fas fa-trash"></i>
                </button> */}
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div class="col-md-12 solo-chain-2">
                  <span class="">Media</span>
                </div>

                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group img-drop">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="image"
                          name="Image"
                          onChange={imageSelector}
                          accept="image/png, image/jpeg"
                        />
                        Click here upload the Featured Image
                      </label>
                      {image !== "" && (
                        <label
                          onClick={() => setImage("")}
                          style={{
                            position: "absolute",
                            right: "20%",
                            top: "20%",
                          }}
                          className="label label-default"
                        >
                          X
                        </label>
                      )}
                      <img
                        alt=""
                        width="150"
                        height="120"
                        src={image}
                        className="rounded mx-auto d-block galimage"
                      ></img>
                      {showCrop && (
                        <Dialog
                          fullScreen={fullScreen}
                          open={open}
                          fullWidth={true}
                          onClose={() => setOpen(false)}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <div className="App">
                            <div className="crop-container">
                              <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            </div>
                            <div className="controls">
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                                classes={{ root: "slider" }}
                              />
                            </div>
                            <div>
                              <button
                                className="btn btn-primary crop-btn"
                                onClick={(e) => showCroppedImage(e)}
                              >
                                Crop
                              </button>
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </div>
                    {validation.imageErr && (
                      <p className="validateERR">{validation.imageErr}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-comb">
                    <div className="form-group img-drop">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="custom-file-upload">
                            <input
                              type="file"
                              id="image1"
                              name="Image1"
                              onChange={imageSelector1}
                              accept="image/png, image/jpeg"
                            />
                            Click here upload the addional Image
                          </label>
                          {image1 !== "" && (
                            <label
                              onClick={() => setImage1("")}
                              style={{
                                position: "absolute",
                                right: "15%",
                                top: "20%",
                              }}
                              className="label label label-default"
                            >
                              X
                            </label>
                          )}
                          <img
                            alt=""
                            width="150"
                            height="120"
                            className="rounded mx-auto d-block galimage"
                            src={image1}
                          ></img>
                          {showCrop1 && (
                            <Dialog
                              fullScreen={fullScreen}
                              open={open1}
                              fullWidth={true}
                              onClose={() => setOpen1(false)}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <div className="App">
                                <div className="crop-container">
                                  <Cropper
                                    image={image1}
                                    crop={cropOne}
                                    zoom={zoomOne}
                                    aspect={4 / 3}
                                    onCropChange={setCropOne}
                                    onCropComplete={onCropCompleteOne}
                                    onZoomChange={setZoomOne}
                                  />
                                </div>
                                <div className="controls">
                                  <Slider
                                    value={zoomOne}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoomOne) =>
                                      setZoomOne(zoomOne)
                                    }
                                    classes={{ root: "slider" }}
                                  />
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary crop-btn"
                                    onClick={(e) => showCroppedImageOne(e)}
                                  >
                                    Crop
                                  </button>
                                </div>
                              </div>
                            </Dialog>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="custom-file-upload">
                            <input
                              type="file"
                              id="image2"
                              name="Image1"
                              onChange={imageSelector2}
                              accept="image/png, image/jpeg"
                            />
                            Click here upload the addional Image
                          </label>
                          {image2 !== "" && (
                            <label
                              onClick={() => setImage2("")}
                              style={{
                                position: "absolute",
                                right: "15%",
                                top: "20%",
                              }}
                              className="label label label-default"
                            >
                              X
                            </label>
                          )}
                          <img
                            alt=""
                            width="150"
                            height="120"
                            src={image2}
                            className="rounded mx-auto d-block galimage"
                          ></img>
                          {showCrop2 && (
                            <Dialog
                              fullScreen={fullScreen}
                              open={open2}
                              fullWidth={true}
                              onClose={() => setOpen2(false)}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <div className="App">
                                <div className="crop-container">
                                  <Cropper
                                    image={image2}
                                    crop={cropTwo}
                                    zoom={zoomTwo}
                                    aspect={4 / 3}
                                    onCropChange={setCropTwo}
                                    onCropComplete={onCropCompleteTwo}
                                    onZoomChange={setZoomTwo}
                                  />
                                </div>
                                <div className="controls">
                                  <Slider
                                    value={zoomTwo}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoomTwo) =>
                                      setZoomTwo(zoomTwo)
                                    }
                                    classes={{ root: "slider" }}
                                  />
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary crop-btn"
                                    onClick={(e) => showCroppedImageTwo(e)}
                                  >
                                    Crop
                                  </button>
                                </div>
                              </div>
                            </Dialog>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-4">
                  <form
                    className="md-form"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="file-field">
                      <div className="btn btn-primary btn-sm float-left">
                        <label>
                          <input
                            type="file"
                            id="excel"
                            name="excel"
                            onChange={ExcelFileUpload}
                            accept=".xls, .xlsx"
                          />
                          <span className="mr-2">Select Excel File</span>
                          <i className="fas fa-pen"></i>
                        </label>
                      </div>
                      {convertedJson.length !== 0 && (
                        <div
                          className="btn btn-primary"
                          onClick={uploadMultipleData}
                        >
                          Upload Data
                        </div>
                      )}
                    </div>
                    <div
                      className="file-field"
                      style={{
                        marginLeft: "1em",
                      }}
                    >
                      <div className="btn btn-primary btn-sm float-left">
                        <label>
                          <input
                            type="file"
                            id="excel"
                            name="excel"
                            onChange={ExcelFileUpload}
                            accept=".xls, .xlsx"
                          />
                          <a
                            href="/Product Catalogue.xlsx"
                            download="file"
                            className="mr-2"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                            }}
                          >
                            Download Template
                          </a>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCatalog;
