import "./Categories.css";
import { useEffect, useState, useCallback, Fragment, useRef } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import XLSX from "xlsx";
import Popup from "reactjs-popup";

const Categories = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [categories, setCategories] = useState([]);
  const [editBoolean, setEditBoolean] = useState(false);
  const [showComponent, setComponent] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [status, setStatus] = useState(false);
  const [file, setFile] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [createdDAte, setCreatedDateE] = useState("");
  const [formValidationError, setformValidationError] = useState({});
  const [minPro, setminPro] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  var perPage = 10;

  const [convertedJson, setConvertedJson] = useState([]);
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetCategories();
    } else {
      ref.current.close();
    }
  };
  const refDelete = useRef();
  const openDeleteTooltip = () => refDelete.current.open();
  const closeDeleteTooltip = (success = false) => {
    if (success) {
      refDelete.current.close();
      // GetAllDeals();
    } else {
      refDelete.current.close();
    }
  };
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ExcelFileUpload = (event) => {
    // console.log(event)
    let selected = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(selected);
    fileReader.onload = (event) => {
      console.log(event);
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: "binary" });
      console.log(workbook);
      workbook.SheetNames.forEach((sheet) => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        console.log(data);
        setConvertedJson(data);
      });
    };
  };
  const validateForm = async () => {
    let data = {};
    if (categoryName.trim().length === 0) {
      data = { name: "Category name is required" };
      setformValidationError({ ...data });
      return data;
    }
    setformValidationError({ ...data });
    return data;
  };
  const uploadMultipleData = async () => {
    const body = {
      json: convertedJson,
    };
    await url
      .post("/category/multiple", body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          alert("File Uploaded Successfully");
        } else {
          alert("Error: Category already exitst");
        }
      })
      .catch((e) => console.log(e));
    setConvertedJson([]);
    await GetCategories();
    await getPaginatedCategories();
  };

  useEffect(() => {
    GetCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    url
      .get(`/category/all/?page=${page}`)
      .then(async (res) => {
        // console.log(res, 'paginated');
        setCategories(res.data.data);
        setUnfiltered(res.data.data);
      })
      .catch((e) => console.log(e));
    GetCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const addCategory = async (blob) => {
    try {
      const check = await validateForm();
      if (Object.keys(check).length === 0) {
        await url
          .post(`/v2/category/`, {
            parentCategory: parentCategory,
            name: categoryName,
            slug: slug,
            image: blob,
            status: status,
          })
          .then((res) => {
            if (res.data.Message === "Success") {
              console.log("added abc", res);
              setParentCategory("");

              setCategoryName("");
              setSlug("");
              setStatus(false);
              setImage("");
              setFile("");

              getPaginatedCategories();
              setComponent(!showComponent);
            }
          })
          .catch((e) => console.log(e));
        await GetCategories();
      }
    } catch (e) {
      console.log(e);
    }
  };
  //clientSide search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
        setCategories(filtered);
        if (filtered.length === 0) {
          openNotFoundPopUp();
        }
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(filtered.length / perPage));
        setPage(1);
      } else {
        setCategories(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );

  const updateCategory = async (blob) => {
    await url
      .put(`/category/?categoryID=${categoryID}`, {
        parentCategory: parentCategory,
        name: categoryName,
        slug: slug,
        image: blob,
        status: status,
      })
      .then((res) => {
        console.log(res, "update");
        if (res.data.Message === "Success") {
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setStatus(false);
          setComponent(false);
          setEditBoolean(false);
          getPaginatedCategories();
        } else {
          alert("Error: Category already exitst");
        }
      })
      .catch((e) => console.log(e));
    await GetCategories();
  };

  const GetCategories = async () => {
    url
      .get(`/category/`)
      .then(async (res) => {
        // console.log(res);
        setCategories(res.data.data);
        setTotalPage(res.data.data.length);
        setminPro(page * 50 - 50 + 1);
      })
      .catch((e) => console.log(e));
  };

  const getPaginatedCategories = async () => {
    await url
      .get(`/category/all/?page=${page}`)
      .then(async (res) => {
        console.log(res, "paginated");
        await setCategories(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const SearchByCategoryName = (value) => {
    console.log("value:", value);
    if (value === "") {
      getPaginatedCategories();
    }
    url.get(`/category/search/?name=${value}`).then(async (res) => {
      // console.log(res, 'response:')
      if (res.data.Message === "Success") {
        setCategories(res.data.data);
      } else {
        // No category found goes here
        openNotFoundPopUp();
        // alert(res.data.data)
      }
    });
  };

  const editCategory = (category) => {
    localStorage.setItem("category", JSON.stringify(category));
    console.log(category);
    // console.log('category', category);
    setCategoryID(category.categoryID);
    setParentCategory(
      category.parentCategory === "" || "Select Parent Category"
        ? ""
        : category.parentCategory
    );
    setCategoryName(category.name);
    setSlug(category.slug);
    setStatus(category.status);
    setImage(category.image);
    setEditBoolean(true);
    setCreatedDateE(category.createdAt);
    setComponent(!showComponent);
  };

  const deleteCategory = async () => {
    await url
      .delete(`/category/?categoryID=${categoryID}`)
      .then((res) => {
        console.log(res, "deleted");
        if (res.data.Message === "Success") {
          openDeleteTooltip();
          setParentCategory("");
          setCategoryName("");
          setSlug("");
          setStatus(false);
          setComponent(false);
          setEditBoolean(false);
          getPaginatedCategories();
        } else {
          alert("Error:", res.data.data);
        }
      })
      .catch((e) => console.log(e));
    await GetCategories();
  };

  const setAddCategoryComponent = () => {
    setCategoryID("");
    setParentCategory("");
    setCategoryName("");
    setSlug("");
    setStatus("");
    setImage("");
    setCreatedDateE("");
    setComponent(!showComponent);
    setEditBoolean(false);
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const closeComp = () => {
    setComponent(false);
    setCategoryID("");
    setParentCategory("");
    setCategoryName("");
    setSlug("");
    setStatus("");
    setImage("");
    setEditBoolean(true);
    setCreatedDateE("");
    setComponent(!showComponent);
  };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateCategory(blob);
            });
        }
      );
    } else {
      updateCategory(image);
    }
  };

  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await addCategory(blob);
            });
        }
      );
    } else {
      addCategory("");
    }
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          <button
            className="btn btn-primary btn-cat"
            onClick={() => setAddCategoryComponent()}
          >
            <i className="fas fa-plus-circle"></i>
            Add Category
          </button>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      // value={categoryName}
                      onChange={(e) => SearchByCategoryName(e.target.value)}
                      placeholder="Search By Category Name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  {!!categories.length && minPro >= 1 && (
                    <Fragment>
                      <span>
                        {minPro}-{categories.length + minPro - 1} of{" "}
                        {totalPage ? totalPage : 0}
                      </span>
                      <div
                        className="btn cannaby-light"
                        onClick={() => decrementPage()}
                        role="button"
                      >
                        <i className="fas fa-chevron-left"></i>
                      </div>
                      <div
                        className="btn cannaby-light"
                        onClick={() => incrementPage()}
                        role="button"
                      >
                        <i className="fas fa-chevron-right"></i>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Products</th>
                    <th scope="col">Parent Category</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          <div style={{ display: "flex" }}>
                            {" "}
                            {category.image ? (
                              <img
                                alt="yo1"
                                src={category.image}
                                className="img-fluid "
                                style={{ width: "40px", height: "40px" }}
                              />
                            ) : (
                              <div
                                className="image-thumbnail-div"
                                style={{
                                  backgroundColor: assignColor(),
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              >
                                <span>{category?.name?.substring(0, 1)}</span>
                              </div>
                            )}
                            <span style={{ marginTop: "0.5em" }}>
                              {category.name}
                            </span>
                          </div>
                        </td>
                        <td>{category.productCount}</td>
                        <td>{category.parentCategory}</td>
                        {/* <td>{moment(category.updatedAt).format(" MMM. Do YYYY, h:mm A")}</td> */}
                        <td className="status">
                          {category.status ? (
                            <span>Active</span>
                          ) : (
                            <span
                              className={`${
                                !category.status ? "colorRed" : ""
                              }`}
                            >
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <i
                            onClick={() => editCategory(category)}
                            className="fas fa-pen"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        {/* {showComponent && <AddCategory editBoolean={editBoolean} setEditBoolean={setEditBoolean} /> } */}
        {showComponent && (
          <div className="card" style={{ position: "fixed", width: "420px" }}>
            <div>
              <div className="headerEA">
                <h4>{editBoolean ? "Edit Categories" : "Add Categories"}</h4>
                <button className="btn close-btn" onClick={closeComp}>
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <br></br>
              <form className="parent-form">
                <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">
                      Parent Category
                    </label>
                    <select
                      onChange={(e) => setParentCategory(e.target.value)}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      {/* <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option> 
                    */}
                      <option value="">
                        {editBoolean && parentCategory !== ""
                          ? parentCategory
                          : "Select Parent Category"}
                      </option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Category Name
                    </label>
                    <input
                      type="text"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name"
                    />
                    {formValidationError.name && (
                      <p style={{ color: "red", fontSize: "11px" }}>
                        {formValidationError.name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="group-comb">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">Slug</label>
                    <input
                      type="text"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="slug"
                    />
                  </div>
                </div>

                <div className="group-comb">
                  <label className="switch switch-demo">
                    <input
                      type="checkbox"
                      checked={status}
                      onChange={() => {
                        setStatus(!status);
                      }}
                    />
                    <span className="slider round"></span>
                    <span
                      className="slider-text"
                      style={
                        status
                          ? { paddingLeft: "81px" }
                          : { paddingLeft: "81px", color: "red" }
                      }
                    >
                      {status ? "Enabled" : "Disabled"}
                    </span>
                  </label>
                </div>

                <div className="group-comb">
                  <div className="form-group img-drop">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        id="image"
                        name="Image"
                        onChange={imageSelector}
                        accept="image/png, image/jpeg"
                      />
                      Click here upload the Featured Image
                    </label>
                    <img
                      alt=""
                      width="150"
                      height="120"
                      src={image}
                      className="rounded mx-auto d-block galimage"
                    ></img>
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div className="App">
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>

                <div className="group flexing ">
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      className="btn btn-primary m-2"
                    >
                      Update
                    </button>
                  )}
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={openTooltip}
                      className="btn btn-danger m-2"
                    >
                      <span>
                        <i className="fa fa-trash-alt"></i>
                      </span>
                    </button>
                  )}
                  {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      className="btn btn-primary m-1"
                    >
                      Publish
                    </button>
                  )}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "}
                  </p>
                ) : (
                  ""
                )}

                {!editBoolean && (
                  <div>
                    <form class="md-form">
                      <div className="file-field">
                        <div className="btn btn-primary btn-sm float-left">
                          <label>
                            <input
                              type="file"
                              id="excel"
                              name="excel"
                              onChange={ExcelFileUpload}
                              accept=".xls, .xlsx"
                            />
                            <span className="mr-2">Select Excel File</span>
                            <i className="fas fa-pen"></i>
                          </label>
                        </div>
                        {convertedJson.length !== 0 && (
                          <div
                            className="btn btn-primary"
                            onClick={uploadMultipleData}
                          >
                            Upload Data
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>

      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <i
            class="fa fa-exclamation-triangle"
            style={{
              color: "red",
              fontSize: "50px",
            }}
            aria-hidden="true"
          ></i>
          <p className="popup-heading">Do you want to delete this category ?</p>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                deleteCategory();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", width: "13%" }}
              type="button"
              className="btn  popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        </div>
      </Popup>
      <Popup ref={refDelete} position="center">
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <i
            class="fa fa-exclamation-triangle"
            style={{
              color: "Green",
              fontSize: "70px",
            }}
            aria-hidden="true"
          ></i>
          <h2 className="popup-heading">Success</h2>
          <p>Catgory deleted successfully</p>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeDeleteTooltip();
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Categories not found</p>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={closeNotFoundPopUp}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Categories;
