import './dashboard.css'
import React, { useState, useEffect } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CategoryIcon from '@mui/icons-material/Category';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import StoreIcon from '@mui/icons-material/Store';
import Robot from '../../images/robot.gif'
import url from '../../config/axios'
import { useHistory } from 'react-router';

export default function Index() {
    const history = useHistory();
    const [outletsLength, setOutletsLength] = useState([]);
    const [productsCatalog, setProductsCatalog] = useState(0);
    const [disabledChains, setDisabledChains] = useState(0);
    const [enabledChained, setEnabledChained] = useState(0);
    const [productsBrand, setProductsBrand] = useState(0);
    const [productsCategories, setProductsCategories] = useState(0);

    useEffect(() => {
        GetAllOutlets();
        GetCategories();
        getAllBrands();
        getAllProducts();
    }, []);

    const GetAllOutlets = () => {
        url.get(`oca`).then(async (res) => {
            //   console.log('res', res)
            if (res.data.Message === 'Success') {
                await setOutletsLength(res.data.data);
                setDisabledChains(res.data.data.filter((arr) => arr.isActive).length, 'active');
                setEnabledChained(res.data.data.filter((arr) => !arr.isActive).length, 'inactive');
            }
        }).catch(e => console.log(e))
    }

    const GetCategories = () => {
        url.get(`/category/`).then(async (res) => {
            //   console.log(res);
            if (res.data.Message === 'Success') {
                setProductsCategories(res.data.data.length)
            }
        }).catch(e => console.log(e));
    }

    const getAllBrands = () => {
        url.get(`/brand/`).then(async (res) => {
            //   console.log(res);
            if (res.data.Message === 'Success') {
                setProductsBrand(res.data.data.length)
            }
        }).catch(e => console.log(e));
    }

    const getAllProducts = () => {
        url.get(`/product/`).then(async (res) => {
            //   console.log(res);
            if (res.data.Message === 'Success') {
                setProductsCatalog(res.data.data.length)
            }
        }).catch(e => console.log(e));
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3 ">
                    <div className="card-dashboard card-cat">
                        <div className="card-dash-icon"><span><LocalOfferIcon /></span></div>
                        <div className="card-dash-text">{productsCatalog}  <span>products in catalog</span> </div>
                        <div className="card-dash-btn"><button className="btn btn-primary btn-icon" onClick={() => history.push('/catalog')}> <AddCircleOutlineIcon /> <span>Add</span></button></div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card-dashboard card-cat">
                        <div className="card-dash-icon"><span><CategoryIcon /></span></div>
                        <div className="card-dash-text">{productsCategories}  <span>products categories</span> </div>
                        <div className="card-dash-btn"><button className="btn btn-primary btn-icon" onClick={() => history.push('/categories')}> <AddCircleOutlineIcon /> <span>Add</span></button></div>
                    </div>
                </div>
                <div className="col-md-3 md-offset-1">
                    <div className="card-dashboard card-cat">
                        <div className="card-dash-icon"><span><BrandingWatermarkIcon /></span></div>
                        <div className="card-dash-text">{productsBrand}  <span>products brands</span> </div>
                        <div className="card-dash-btn"><button className="btn btn-primary btn-icon" onClick={() => history.push('/brands')}> <AddCircleOutlineIcon /> <span>Add</span></button></div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-md-3 ">
                    <div className="card-dashboard card-cat">
                        <div className="card-dash-icon"><span><StoreIcon /></span></div>
                        <div className="card-dash-text">{outletsLength === [] ? 0 : outletsLength.length}  <span>registered outlet chains</span> </div>
                        <div className="card-dash-btn"><button className="btn btn-primary btn-icon" onClick={() => history.push('/outletchains')}> <AddCircleOutlineIcon /> <span>Add</span></button></div>
                        <div className="card-dash-stat">
                            <div>Status</div>
                            <div className="stat-disabled">Disabled Chains <span>{disabledChains}</span></div>
                            <div className="stat-enabled">Enabledbled Chains<span>{enabledChained}</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card-final card-cat">
                        <div >
                            <label className="extra-card">Glad to have you back.. Have a great day!</label>
                            <img alt="" className="robo" src={Robot} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}