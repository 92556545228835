import './Categories/Categories.css'
import { useHistory } from "react-router-dom";

const HeaderLeft = (props) =>{
  const history = useHistory();
    return (
      <div className="col-md-7 col-header">
      <span className="navbar-brand" > 
        <span className="goback-nav" onClick={() => history.goBack()}><i className="fas fa-chevron-left"></i></span> 
        <h2>{props.name}</h2>
      </span>
      {/* <button className="btn btn-primary">
        <i className="fas fa-plus-circle"></i>
        Add {props.name}
      </button> */}
    </div>
    )
}


export default HeaderLeft;