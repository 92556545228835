import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const VariantPage = ({ handleVariants, variantsData }) => {
  const [inputFields, setInputFields] = useState([
    { uom: "g", price: "", weight: "" },
  ]);
  console.log("Here is my variant data: ", variantsData);
  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    console.log("Dynamic data: ", data);
    setInputFields(data);
    handleVariants(data);
  };

  useEffect(() => {
    if (variantsData) {
      setInputFields(variantsData);
    }
  }, []);

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { uom: "g", price: "", weight: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  return (
    <form
      style={{
        width: "100%",
      }}
    >
      {inputFields.map((input, index) => {
        return (
          <>
            <div>
              <CancelIcon
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginRight: "1em",
                  marginTop: "0.5em",
                  color: "#5D5FEF",
                }}
                onClick={() => removeFields(index)}
              />
            </div>
            <br />
            <div
              style={{
                display: "flex",
                marginTop: "1em",
              }}
            >
              <div className="col-md-6">
                <div className="form-group">
                  <label>Variant Weight*</label>
                  <input
                    type="text"
                    name="weight"
                    placeholder="weight"
                    value={input.weight}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Product Price*</label>
                  <input
                    type="text"
                    name="price"
                    placeholder="price"
                    value={input.price}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </div>
              </div>
            </div>
            <br />
            <div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Product Unit</label>
                  <input
                    type="text"
                    name="uom"
                    placeholder="grams"
                    value={"g"}
                    onChange={(event) => handleFormChange(index, event)}
                    className="form-control"
                    id="exampleFormControlInput1"
                    disabled
                  />
                </div>
              </div>
            </div>
          </>
        );
      })}
      <div
        className="variation_button"
        style={{
          float: "left",
          marginLeft: "1em",
          marginBottom: "0.5em",
        }}
      >
        <button
          onClick={addFields}
          style={{
            background: "none",
            border: "none",
            textDecoration: "underline",
            color: "#5D5FEF",
            outline: "none",
            cursor: "pointer",
          }}
        >
          {" "}
          <AddCircleIcon
            style={{
              position: "relative",
              top: "0.2em",
              right: "0.3em",
              fontSize: "1.7em",
            }}
          />{" "}
          Add Variant
        </button>
      </div>
    </form>
  );
};

export default VariantPage;
