import axios from "axios";

const customInstance = axios.create({
  // baseURL: 'https://standalone-backend.bleaum.dev/',,
  baseURL: "https://standalone-backend.staging.bleaum.dev/",
  // baseURL: "http://localhost:8080/",
  headers: { Accept: "application/json" },
});

export default customInstance;
