import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Categories from '../components/Categories/Categories';
import Catalog from '../components/Catalog/Catalog';
import AddCatalog from '../components/Catalog/AddCatalog';
import EditCatalog from '../components/Catalog/EditCatalog';
import Brands from '../components/Brands/Brands';
import OutletChainAdmin from '../components/OutletChainAdmin/OutletChainAdmin';
import Dashboard from '../components/Dashboard';
import NotFound from '../components/NotFound';
import Unapproved from '../components/UnapprovedProducts';
import EditUnapproved from '../components/UnapprovedProducts/EditUnapprovedProducts';

const Routes = () => {
  return (
    <section>
      <Switch>
        <Route exact path="/catalog" component={Catalog}></Route>
        <Route exact path="/categories" component={Categories}></Route>
        <Route exact path="/addCatalog" component={AddCatalog}></Route>
        <Route exact path="/editCatalog" component={EditCatalog}></Route>
        <Route exact path="/brands" component={Brands}></Route>
        <Route exact path="/dashboard" component={Dashboard}></Route>
        <Route exact path="/outletchains" component={OutletChainAdmin}></Route>
        <Route exact path="/unapproved-products" component={Unapproved}></Route>
        <Route exact path="/edit-unapproved-products" component={EditUnapproved}></Route>
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
