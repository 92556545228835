import React from 'react'
import { useEffect, useState } from 'react'
import url from '../../config/axios'
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function Index() {
    // eslint-disable-next-line no-unused-vars
    const history = useHistory();

    const [products, setProducts] = useState([]);

    useEffect(() => {
        getUnapprovedProducts();
    }, [])

    const getUnapprovedProducts = () => {
        url.get(`/product/missing/`).then(async(res) => {
            if(res.data.Message === "Success") {
                console.log(res);
                setProducts(res.data.data);
            }
            else{
                alert(res.data.data)
            }
        })
    }

    const moveToEdit = (product) => {
        localStorage.setItem("product", JSON.stringify(product));
        history.push('/edit-unapproved-products')
      }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card card-pro">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group">

                                        <input type="text" className="form-control table-search" placeholder="Search" aria-label="Input group example" aria-describedby="btnGroupAddon" />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text table-search-icon" id="btnGroupAddon">
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 filters-bar">
                                    <div className="dropdown">
                                        {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                        {/* <i className="fas fa-filter"></i> */}
                                        {/* </a> */}

                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="/">Action</a>
                                            <a className="dropdown-item" href="/">Another action</a>
                                            <a className="dropdown-item" href="/">Something else here</a>
                                        </div>
                                    </div>
                                    <div className="dropdown">
                                        {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                        {/* <i className="fas fa-filter"></i> */}
                                        {/* </a> */}

                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" href="/">Action</a>
                                            <a className="dropdown-item" href="/">Another action</a>
                                            <a className="dropdown-item" href="/">Something else here</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pagination">
                                    <span>
                                        
                                    </span>
                                    <div className="btn cannaby-light"  role="button" >
                                        <i className="fas fa-chevron-left"></i>
                                    </div>
                                    <div className="btn cannaby-light" role="button">
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <table className="table table-striped-cust">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>

                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Brand</th>
                                        <th scope="col">Last Modified</th>
                                        <th scope="col" className="hidden">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        products.map((product, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td colSpan="6" >
                                                        <img img alt="yo1" src={product.image} className="img-fluid " width="40" />
                                                        {product.name}
                                                    </td>
                                                    <td>{product.category[0] ? product.category[0].name : ''}</td>
                                                    <td>{product.brandName}</td>
                                                    <td>{moment(product.updatedAt).format(" MMM. Do YYYY, h:mm A")}</td>
                                                    <td className="status hidden">
                                                        {product.status ? (
                                                            <span>Published</span>
                                                        ) : (
                                                            <span>Not Published</span>
                                                        )
                                                        }

                                                    </td>
                                                    <td><i className="fas fa-pen" onClick={() => moveToEdit(product)}></i></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}
