import React, { useState, useEffect, useCallback } from 'react';
import './Header.css';
import HeaderLeft from './HeaderLeft'
import { Route } from 'react-router-dom';
import storage from '../config/firebase';
import url from '../config/axios'
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider';
import getCroppedImg from '../helpers/cropImage';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import data from '../helpers/countries.json'
import Select from 'react-select';

function Header() {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [showProfile, setShowProfile] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [selectCountry, setSelectCountry] = useState([]);

  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, setPhoneCode] = useState("");


  const [error, setError] = useState("")
  useEffect(() => {
    getAdminDetails();
    getCountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({ value: e.dial_code, label: e.flag + e.dial_code, flag: e.flag, code: e.code, dial_code: e.dial_code })
    })
    setSelectCountry(x)
    // console.log(x)
  }

  const showCroppedImage = useCallback(async (e) => {
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(
        editImage,
        croppedAreaPixels,
        0
      )
      console.log('donee', { croppedImage });
      localStorage.setItem("ImageSet", croppedImage);
      setEditImage(croppedImage);
      setShowCrop(false);
      setOpen(false);
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, editImage])

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setEditImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  }

  const updateAdmin = (blob) => {
    const userDetails = JSON.parse(localStorage.getItem("AdminDetails"));
    let body = {
      adminName: name,
      email: email,
      phoneNo: phoneNo,
      countryCode: phoneCode,
      image: blob,
    }
    url.put(`/admin/?adminID=${userDetails[0].adminID}`, body).then((res) => {
      console.log(res, 'admin Details');
      if (res.data.Message === "Success") {
        setName(res.data.data.adminName)
        setEmail(res.data.data.email)
        setPhoneNo(res.data.data.phoneNo)
        setPhoneCode(res.data.data.countryCode);
        setImage(res.data.data.image)
        setEditAdmin(false);
        setShowProfile(false);
      }
      else {
        console.log(res, 'admin Details');

        setError(res.data.data)
      }
    }).catch(e => console.log(e));
  }

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = storage.ref(`images/${file.name}`).putString(localStorage.getItem("ImageSet"), 'data_url');
    if (file !== "") {
      uploadTask.on(
        "state_changed",
        snapshot => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateAdmin(blob);
            });
        });
    }
    else {
      updateAdmin("");
    }
  }

  const getAdminDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem("AdminDetails")) || null;
    if (userDetails !== null) {
      setName(userDetails[0].adminName)
      setEmail(userDetails[0].email)
      setPhoneNo(userDetails[0].phoneNo)
      setPhoneCode(userDetails[0].countryCode);
      setImage(userDetails[0].image)
      setEditImage(userDetails[0].image);
    }


    url.get(`/admin/id/?adminID=${userDetails[0].adminID}`).then((res) => {
      console.log(res, 'admin Details');
      if (res.data.Message === "Success") {
        setName(res.data.data[0].adminName)
        setEmail(res.data.data[0].email)
        setPhoneNo(res.data.data[0].phoneNo)
        setPhoneCode(res.data.data.countryCode);
        setImage(res.data.data[0].image)
        setEditImage(res.data.data[0].image);
      }
    }).catch(e => console.log(e));
  }
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg">

        <div className="collapse navbar-collapse" id="navbarText">
          <div className="row row-header">

            <Route path="/categories"> <HeaderLeft name="Categories" /></Route >
            <Route path="/brands"> <HeaderLeft name="Manufacturers" /></Route >
            <Route path="/outletchains"> <HeaderLeft name="Outlet Chain" /></Route >
            <Route path="/catalog"> <HeaderLeft name="Catalog" /></Route >
            <Route path="/unapproved-products"> <HeaderLeft name="Unapproved Products" /></Route >
            <Route path="/addCatalog"> <HeaderLeft name="Add Catalog" /></Route >
            <Route path="/editCatalog"> <HeaderLeft name="Edit Catalog" /></Route >
            <Route path="/dashboard"> <HeaderLeft name="Dashboard" /></Route >

            <div className="col-md-5 user-head">
              <span className="notification-icons">
                {/* <span><i className="far fa-question-circle"></i></span>
                        <span><i className="fas fa-headset"></i></span>
                        <span><i className="far fa-bell"></i></span> */}
              </span>

              <span className="navbar-text">
                <div className="container d-flex justify-content-center">
                  <div className="">
                    <div className="top-container">
                      {image ? <img alt="yo4" src={image} onClick={() => setShowProfile(!showProfile)} className="img-fluid profile-image" width="53" />
                        :
                        <div
                          className="image-thumbnail-div"
                          style={{
                            backgroundColor: assignColor(),
                            width: "40px",
                            height: "40px",
                            borderRadius: "5px",
                          }}>
                          <span>{name?.substring(0, 1)}</span>
                        </div>
                      }

                      <div className="ml-3">
                        <h5 className="name">{name}</h5>
                        <p className="mail">Super Admin</p>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </nav>
      {showProfile &&
        <div className="profile-section">
          <div className="profile-parent">
            <label onClick={() => setShowProfile(false)} className="label-close" style={{ cursor: 'pointer', margin: '15px' }}><i class="fas fa-times"></i></label>
            <span className="navbar-text">
              <div className="parent-right-nav container d-flex justify-content-center">
                <div className="">
                  <div className="top-container-rnav">


                    <form>
                      <div className="rnav-image">
                        <div className="image-container-rnav">
                          <img alt="yo4" src={editImage} className="img-fluid profile-image" width="53" />

                          <label className="custom-file-upload-rnav">

                            <input
                              type="file"
                              id="image1"
                              disabled={!editAdmin}
                              name="Image1"
                              onChange={imageSelector}
                              accept="image/png, image/jpeg"
                            />
                            <i className="fas fa-pen"></i>
                          </label>
                          {showCrop &&
                            <Dialog
                              fullScreen={fullScreen}
                              open={open}
                              fullWidth={true}
                              onClose={() => setOpen(false)}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <div className="App">
                                <div className="crop-container">
                                  <Cropper
                                    image={editImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                  />
                                </div>
                                <div className="controls">
                                  <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    classes={{ root: 'slider' }}
                                  />
                                </div>
                                <div>
                                  <button className="btn btn-primary crop-btn" onClick={(e) => showCroppedImage(e)}>Crop</button>
                                </div>
                              </div>
                            </Dialog>
                          }
                        </div>

                      </div>
                      <div className={"rnav-name " + (editAdmin ? 'rnav-input-active' : '')}>
                        <label >Name</label>
                        <input type="text" disabled={!editAdmin} value={name}
                          onChange={(e) => setName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" />
                      </div>
                      <div className={"rnav-phone " + (editAdmin ? 'rnav-input-active' : '')}>
                        <div className="row">
                          <div className="col-md-4">

                          </div>
                          <div className="col-md-8">
                            <label className="phone-label">Phone</label>
                          </div>
                          <div className="col-md-5 pin-input-rnav" >
                            <Select
                              name="colors"
                              defaultValue={selectCountry[2]}
                              options={selectCountry}
                              style={{ backgroundColor: "white" }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                console.log(e.dial_code);
                                setPhoneCode(e.dial_code)
                              }}
                            />
                          </div>
                          <div className="col-md-7  phone-input-rnav">

                            <input type="number" disabled={!editAdmin} value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="phone no" />

                          </div>

                        </div>
                      </div>

                      <div className={"rnav-name " + (editAdmin ? 'rnav-input-active' : '')} >

                        <label >Email</label>
                        <input type="text" disabled={!editAdmin} value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="email" />

                      </div>

                    </form>
                    <div className="rnav-btn-grp">
                      {!editAdmin && <button className="btn btn-rnav" onClick={() => setEditAdmin(true)}>
                        Edit
                      </button>}
                      {editAdmin &&
                        <div>
                          <button className="btn btn-rnav" onClick={() => setEditAdmin(false)}>
                            Cancel
                          </button>
                          <button className="btn btn-rnav" onClick={() => fileUpdate()}>
                            Save
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <div className="nav-msg">
              <p>This is a super admin account</p>
            </div>
          </div>

        </div>
      }
    </>
  )
}


export default Header;